// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-covid-19-tsx": () => import("./../src/pages/covid-19.tsx" /* webpackChunkName: "component---src-pages-covid-19-tsx" */),
  "component---src-pages-help-tsx": () => import("./../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pharmacies-tsx": () => import("./../src/pages/pharmacies.tsx" /* webpackChunkName: "component---src-pages-pharmacies-tsx" */),
  "component---src-pages-pharmacy-service-tsx": () => import("./../src/pages/pharmacy-service.tsx" /* webpackChunkName: "component---src-pages-pharmacy-service-tsx" */),
  "component---src-pages-portal-blocked-tsx": () => import("./../src/pages/portal-blocked.tsx" /* webpackChunkName: "component---src-pages-portal-blocked-tsx" */),
  "component---src-pages-services-iframe-tsx": () => import("./../src/pages/services-iframe.tsx" /* webpackChunkName: "component---src-pages-services-iframe-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

